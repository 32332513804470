import { TeamModel } from '@/models/team';
import { isEmpty, title } from '@/pipes';
import { Component, Prop, Vue } from 'vue-property-decorator';

/**
 * Provides computed props to display information on a team
 */
@Component
export class TeamDetailsMixin extends Vue {
	@Prop({ required: true }) team: TeamModel;

	get TeamName(): string {
		return this.team.name;
	}
	get TeamLocation(): string {
		return this.team.Location;
	}

	get TeamCountry(): string | null {
		if (!this.team['country']) return null;
		return this.team['country'];
	}


	// team record
	get TeamW(): string {
		if( !this.team?.wtlSummary ) return '0';
		return this.team.wtlSummary.W.toString();
	}
	get TeamD(): string {
		if( !this.team?.wtlSummary ) return '0';
		return this.team.wtlSummary.T.toString();
	}
	get TeamL(): string {
		if( !this.team?.wtlSummary ) return '0';
		return this.team.wtlSummary.L.toString();
	}

	get WTLSummary(): string {
		return `${this.team.wtlSummary.W} - ${this.team.wtlSummary.T} - ${this.team.wtlSummary.L}`;

		return `0 - 0 - 0`;
	}

	get DetailsTitle(): string {
		let gender: string;
		switch (this.team.gender.toLowerCase()){
		case 'male':
			gender = "Mens";
			break;
		case 'female':
			gender = "Womens";
			break;
		default:
			gender = "Coed";
			break;
		}
		const city = this.TeamLocation !== null ? ` | ${this.TeamLocation}` : '';
		return `${gender} ${title(this.team.sportId)}${city}`;
	}
	
	gotoTeamDashboard(){
		this.$router.push({ name: 'TeamDashboard', params: { teamId: this.team.id }})
	}
}
