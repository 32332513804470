import store from '../store';
import { notificationStore, sportStore } from '@/store';
import { Module, VuexModule, Mutation, Action, } from 'vuex-module-decorators';
import { AthleteProfileModel } from '../../models/athlete/AthleteProfileModel'
import { athleteApi } from '../../api/AthleteApi';
import { athleteAssessmentDataApi } from '../../api/AthleteAssessmentDataApi';
import { selfAssessmentApi } from '../../api/SelfAssessmentApi';
import { TeamModel } from '../../models/team';
import { teamApi } from '../../api/TeamApi';
import { AthleteAssessmentDataModel } from '../../models/athlete/AthleteAssessmentDataModel';
import { AgeGroup, UnderU13AgeGroupValues, Under13AgeGroup } from '@best-athletes/ba-types';
import { SelfAssessmentModel } from '../../models/athlete/SelfAssessmentModel';
import { AthleteUpdateOptions } from '@/../types/interfaces';
import { RecruitingProfileGetParam } from "@/../types/interfaces";
import { NotificationModel } from '@/models/notification/NotificationModel';
import { MindsetReportModel } from '@/models/mindset/MindsetReportModel';

export interface PatchAthleteProfileOptions {
	patch: Partial<AthleteProfileModel>,
	options?: { withAcls?: boolean },
	updateOptions?: AthleteUpdateOptions
}

const Mutations = {
	GET_PROFILE: 'GET_PROFILE',
	GET_PROFILE_SUCCESS: 'GET_PROFILE_SUCCESS',
	GET_PROFILE_FAILURE: 'GET_PROFILE_FAILURE',

	PATCH_PROFILE: 'PATCH_PROFILE',
	PATCH_PROFILE_SUCCESS: 'PATCH_PROFILE_SUCCESS',
	PATCH_PROFILE_FAILURE: 'PATCH_PROFILE_FAILURE',

	SET_PROFILE_PICTURE: 'SET_PROFILE_PICTURE',
	SET_PROFILE_PICTURE_SUCCESS: 'SET_PROFILE_PICTURE_SUCCESS',
	SET_PROFILE_PICTURE_FAILURE: 'SET_PROFILE_PICTURE_FAILURE',

	LOAD_VERIFIED_ASSESSMENTS: 'LOAD_VERIFIED_ASSESSMENTS',
	LOAD_VERIFIED_ASSESSMENTS_SUCCESS: 'LOAD_VERIFIED_ASSESSMENTS_SUCCESS',
	LOAD_VERIFIED_ASSESSMENTS_FAILURE: 'LOAD_VERIFIED_ASSESSMENTS_FAILURE',

	LOAD_SELF_ASSESSMENTS: 'LOAD_SELF_ASSESSMENTS',
	LOAD_SELF_ASSESSMENTS_SUCCESS: 'LOAD_SELF_ASSESSMENTS_SUCCESS',
	LOAD_SELF_ASSESSMENTS_FAILURE: 'LOAD_SELF_ASSESSMENTS_FAILURE',

	LOAD_ACTIVE_SELF_ASSESSMENT: 'LOAD_ACTIVE_SELF_ASSESSMENT',
	LOAD_ACTIVE_SELF_ASSESSMENT_SUCCESS: 'LOAD_ACTIVE_SELF_ASSESSMENT_SUCCESS',
	LOAD_ACTIVE_SELF_ASSESSMENT_FAILURE: 'LOAD_ACTIVE_SELF_ASSESSMENT_FAILURE',

	PATCH_SELF_ASSESSMENT: 'PATCH_SELF_ASSESSMENT',
	PATCH_SELF_ASSESSMENT_SUCCESS: 'PATCH_SELF_ASSESSMENT_SUCCESS',
	PATCH_SELF_ASSESSMENT_FAILURE: 'PATCH_SELF_ASSESSMENT_FAILURE',
}

const name = 'AthleteProfileStore';

if (store.state[name]) {
	store.unregisterModule(name)
}
@Module({
	namespaced: true,
	dynamic: true,
	name,
	store: store
})
/**
 * This store loads athlete profiles which aren't necessarily the user's profile.
 */
export default class AthleteProfileModule extends VuexModule {

	/**
	 * Anything is loading
	 */
	get IsLoading(): boolean{
		return this.loading || this.patchProfileLoading || this.setAthleteProfilePictureLoading;
	}

	/**
	 * True if athlete is 18 or over
	 */
	get Over18(): boolean{
		if( !this.AssessmentDataIsReady ) return false;
		return this.athleteAssessmentData.age >= 18;
	}

	get AthleteProfileIsReady(): boolean{
		return this.athleteProfileInitialized && !this.loading && this.athleteProfile !== null;
	}
	get AssessmentDataIsReady(): boolean{
		return this.athleteProfileInitialized && !this.loading && this.athleteAssessmentData !== null;
	}

	loading: boolean = false;

	athleteProfileInitialized: boolean = false;
	athleteProfile: AthleteProfileModel | null = null;
	athleteProfileTeams: TeamModel[] = [];
	athleteAssessmentData: AthleteAssessmentDataModel | null = null;
	selfAssessmentSummary: SelfAssessmentModel | null = null;
	mindsetReport: MindsetReportModel | null = null;

	get AthleteAgeGroup(): AgeGroup | null{
		if (!this.athleteAssessmentData) return null;
		if (this.AthleteAgeGroupIsUnderU13){
			return AgeGroup.U13;
		}
		return this.athleteAssessmentData.playingLevel;
	}
	get AthleteAgeGroupLabel(): string | null{
		if (!this.athleteAssessmentData) return null;
		return this.athleteAssessmentData.playingLevel;
	}
	get AthleteAgeGroupIsUnderU13(): boolean{
		return UnderU13AgeGroupValues.includes(this.athleteAssessmentData.playingLevel as unknown as Under13AgeGroup)
	}

	@Action({
		rawError: true,
	}) async getAthleteProfile(id: string): Promise<void> {
		if( !id ) return;

		this.context.commit(Mutations.GET_PROFILE, id);
		try {
			sportStore.loadSports();
			const [ athleteProfile, athleteTeams, assessmentData, selfAssessment ] = await Promise.all([
				athleteApi.findById(id),
				teamApi.findByAthleteId({ athleteId: id }),
				athleteAssessmentDataApi.findLatestByAthleteId({ athleteId: id }),
				selfAssessmentApi.getLatestSelfAssessmentResults({ athleteId: id }),
			]);
			if( !!assessmentData ){
				this.context.dispatch(
					'ComparativeDataStore/getUserPerformanceCategories',
					{
						assessmentId: assessmentData.id,
					},
					{root: true}
				);
			}else{
				this.context.dispatch(
					'ComparativeDataStore/clearUserPerformanceCategories',
					null,
					{ root: true }
				);
			}
			this.context.commit(Mutations.GET_PROFILE_SUCCESS, {
				profile: athleteProfile,
				teams: athleteTeams,
				assessmentData: assessmentData,
				selfAssessment: selfAssessment,
			})
		} catch (e) {
			console.error("Failed to Load Athlete Profile", { id, e });
			this.context.commit(Mutations.GET_PROFILE_FAILURE, e);
		}
	}

	@Action({ rawError: true })
	async getAthleteRecruitingProfile({id, options}: {id: string, options: RecruitingProfileGetParam}): Promise<void> {
		if (!id) {
			// console.log("No ID provided")
			return
		}
		this.context.commit(Mutations.GET_PROFILE, id);

		try {
			// Recruiting Profile is the same as athlete profile with the teams, self assessments, and verified assessments
			// and it will be null for fields are made public to the current user
			const {selfAssessment, physicalPerformance, mindsetReport, teams, athleteProfile} = await athleteApi.getAthleteRecruitingProfile(id, options);

			// Commit
			this.context.commit(Mutations.GET_PROFILE_SUCCESS, {
				profile: athleteProfile,
				teams: teams,
				assessmentData: physicalPerformance,
				selfAssessment,
				mindsetReport,
			})
		}
		catch (e) {
			console.log("Failed to Load Athlete Recruiting Profile", e);
			this.context.commit(Mutations.GET_PROFILE_FAILURE, e);
		}
	}

	@Mutation [Mutations.GET_PROFILE](): void {
		this.loading = true;
	}
	@Mutation [Mutations.GET_PROFILE_SUCCESS]({ profile, teams, assessmentData, selfAssessment, mindsetReport }: { profile: AthleteProfileModel, teams: TeamModel[], assessmentData: AthleteAssessmentDataModel | null, selfAssessment: SelfAssessmentModel | null, mindsetReport: MindsetReportModel | null }): void {
		this.athleteProfile = profile;
		this.athleteProfileTeams = teams.map(t => new TeamModel().load(t));
		this.athleteAssessmentData = assessmentData;
		this.selfAssessmentSummary = selfAssessment;
		this.mindsetReport = mindsetReport;

		this.loading = false;
		this.athleteProfileInitialized = true;
	}
	@Mutation [Mutations.GET_PROFILE_FAILURE](error: any): void {
		notificationStore.pushErrorNotification(new NotificationModel(error.message));
		this.loading = false;
	}

	patchProfileLoading: boolean = false;
	@Action({
		rawError: true,
	}) async patchAthleteProfile({patch,options={},updateOptions}: PatchAthleteProfileOptions): Promise<void> {
		const { withAcls = true } = options;
		this.context.commit(Mutations.PATCH_PROFILE);
		try {
			const updatedProfile = withAcls ? await athleteApi.patchWithAcls(patch, {params:updateOptions}) : await athleteApi.patch(patch, {params:updateOptions});
			this.context.commit(Mutations.PATCH_PROFILE_SUCCESS, {
				profile: updatedProfile,
			})
			this.context.dispatch('UserStore/pushAthleteProfile', updatedProfile, { root: true });
		} catch (e) {
			console.error("Failed to Patch Athlete Profile", { e });
			this.context.commit(Mutations.PATCH_PROFILE_FAILURE, e);
		}
	}

	@Mutation [Mutations.PATCH_PROFILE](): void {
		this.patchProfileLoading = true;
	}
	@Mutation [Mutations.PATCH_PROFILE_SUCCESS]({ profile }: { profile: AthleteProfileModel, teams: TeamModel[], assessmentData: AthleteAssessmentDataModel}): void {
		this.athleteProfile = profile;
		this.patchProfileLoading = false;
	}
	@Mutation [Mutations.PATCH_PROFILE_FAILURE](error: any): void {
		this.patchProfileLoading = false;
	}

	setAthleteProfilePictureLoading: boolean = false;
	@Action({
		rawError: true,
	}) async setAthleteProfilePicture({ athleteId, pictureUrl }: { athleteId: string, pictureUrl: string }): Promise<void> {
		this.context.commit(Mutations.SET_PROFILE_PICTURE);
		try {
			if (!this.athleteProfileInitialized) throw new Error("Athlete Profile is not ready");
			await athleteApi.patch({
				id: athleteId,
				pictureUrl,
			});
			this.context.commit(Mutations.SET_PROFILE_PICTURE_SUCCESS, pictureUrl);
		} catch (e) {
			console.error("Failed to update Athlete Picture Url", e);
			this.context.commit(Mutations.SET_PROFILE_PICTURE_FAILURE, e);
		}
	}

	@Mutation [Mutations.SET_PROFILE_PICTURE](): void {
		this.setAthleteProfilePictureLoading = true;
	}
	@Mutation [Mutations.SET_PROFILE_PICTURE_SUCCESS](pictureUrl: string): void {
		this.athleteProfile.pictureUrl = pictureUrl;
		this.setAthleteProfilePictureLoading = false;
	}
	@Mutation [Mutations.SET_PROFILE_PICTURE_FAILURE](error: any): void {
		this.setAthleteProfilePictureLoading = false;
	}

	verifiedAssessmentsLoading: boolean = false;
	verifiedAssessments: AthleteAssessmentDataModel[] = [];
	@Action({
		rawError: true,
	}) async loadVerifiedAssessments({ athleteId }: { athleteId: string }): Promise<void> {
		this.context.commit(Mutations.LOAD_VERIFIED_ASSESSMENTS);
		try {
			const assessments = await athleteAssessmentDataApi.findAllByAthleteId({ athleteId });
			this.context.commit(Mutations.LOAD_VERIFIED_ASSESSMENTS_SUCCESS, assessments);
		} catch (e) {
			console.error("Failed to update Athlete Picture Url", e);
			this.context.commit(Mutations.LOAD_VERIFIED_ASSESSMENTS_FAILURE, e);
		}
	}

	@Mutation [Mutations.LOAD_VERIFIED_ASSESSMENTS](): void {
		this.verifiedAssessmentsLoading = true;
	}
	@Mutation [Mutations.LOAD_VERIFIED_ASSESSMENTS_SUCCESS](assessments: AthleteAssessmentDataModel[]): void {
		this.verifiedAssessments = assessments;
		this.verifiedAssessmentsLoading = false;
	}
	@Mutation [Mutations.LOAD_VERIFIED_ASSESSMENTS_FAILURE](error: any): void {
		this.verifiedAssessmentsLoading = false;
	}

	selfAssessmentsLoading: boolean = false;
	selfAssessments: SelfAssessmentModel[] = [];
	@Action({
		rawError: true,
	}) async loadSelfAssessments({ athleteId }: { athleteId: string }): Promise<void> {
		this.context.commit(Mutations.LOAD_SELF_ASSESSMENTS);
		try {
			const assessments = await selfAssessmentApi.findAllByAthleteId({ athleteId });
			this.context.commit(Mutations.LOAD_SELF_ASSESSMENTS_SUCCESS, assessments);
		} catch (e) {
			console.error("Failed to update Athlete Picture Url", e);
			this.context.commit(Mutations.LOAD_SELF_ASSESSMENTS_FAILURE, e);
		}
	}

	@Mutation [Mutations.LOAD_SELF_ASSESSMENTS](): void {
		this.selfAssessmentsLoading = true;
	}
	@Mutation [Mutations.LOAD_SELF_ASSESSMENTS_SUCCESS](assessments: SelfAssessmentModel[]): void {
		this.selfAssessments = assessments;
		this.selfAssessmentsLoading = false;
	}
	@Mutation [Mutations.LOAD_SELF_ASSESSMENTS_FAILURE](error: any): void {
		this.selfAssessmentsLoading = false;
	}

	activeSelfAssessmentLoading: boolean = false;
	activeSelfAssessment: SelfAssessmentModel | null = null;
	@Action({
		rawError: true,
	}) async createOrLoadActiveSelfAssessment({ athleteId }: { athleteId: string }): Promise<void> {
		this.context.commit(Mutations.LOAD_ACTIVE_SELF_ASSESSMENT);
		try {
			let assessment = await selfAssessmentApi.findLatestByAthleteId({ athleteId });
			if(assessment === null || assessment.IsExpired || assessment.IsComplete){
				assessment = await selfAssessmentApi.insertWithOwnership(new SelfAssessmentModel().load({ athleteId }));
			}
			this.context.commit(Mutations.LOAD_ACTIVE_SELF_ASSESSMENT_SUCCESS, assessment);
		} catch (e) {
			console.error(e);
			this.context.commit(Mutations.LOAD_ACTIVE_SELF_ASSESSMENT_FAILURE, e);
		}
	}

	@Mutation [Mutations.LOAD_ACTIVE_SELF_ASSESSMENT](): void {
		this.activeSelfAssessmentLoading = true;
	}
	@Mutation [Mutations.LOAD_ACTIVE_SELF_ASSESSMENT_SUCCESS](assessment: SelfAssessmentModel): void {
		this.activeSelfAssessment = assessment;
		this.activeSelfAssessmentLoading = false;
	}
	@Mutation [Mutations.LOAD_ACTIVE_SELF_ASSESSMENT_FAILURE](error: any): void {
		this.activeSelfAssessmentLoading = false;
	}

	patchSelfAssessmentLoading: boolean = false;
	@Action({
		rawError: true,
	}) async patchSelfAssessment({ patch }: { patch: Partial<SelfAssessmentModel> }): Promise<void> {
		this.context.commit(Mutations.PATCH_SELF_ASSESSMENT);
		try {
			const assessment = await selfAssessmentApi.patch(patch);
			this.context.commit(Mutations.PATCH_SELF_ASSESSMENT_SUCCESS, assessment);
		} catch (e) {
			console.error(e);
			this.context.commit(Mutations.PATCH_SELF_ASSESSMENT_FAILURE, e);
		}
	}

	@Mutation [Mutations.PATCH_SELF_ASSESSMENT](): void {
		this.patchSelfAssessmentLoading = true;
	}
	@Mutation [Mutations.PATCH_SELF_ASSESSMENT_SUCCESS](assessment: SelfAssessmentModel): void {
		this.activeSelfAssessment = assessment;
		this.patchSelfAssessmentLoading = false;
	}
	@Mutation [Mutations.PATCH_SELF_ASSESSMENT_FAILURE](error: any): void {
		this.patchSelfAssessmentLoading = false;
	}
	
}
