import { Component, Mixins, Prop } from 'vue-property-decorator';
import { AppHostnameMixin, AthleteApiMixin } from '@/mixins'
import { AthleteProfileModel, BAEventModel, BAPartnerProduct } from '@/models';
import { athleteApi } from '@/api/AthleteApi';
import { baPurchaseApi } from '@/api/BAPurchaseApi';
import { baEventApi } from '@/api/BAEventsApi';
import { userStore } from '@/store';
import { isEmpty, isNotEmpty } from '@/pipes';

/**
 * Provides helpers to access athlete profile
 */
@Component
export class AthleteProfileMixin extends Mixins(AppHostnameMixin, AthleteApiMixin) {
	@Prop({ type: String, required: true }) athleteId;

	mounted() {
		this.loadAthleteProfile();
	}

    profileError: string;
	athleteProfile: AthleteProfileModel = new AthleteProfileModel;
	loadingAthleteProfile = true;
	async loadAthleteProfile() {
		if( isEmpty(this.AthleteId) ) return;
		
		this.loadingAthleteProfile = true;
		await this.loadSports();
		try{
			this.athleteProfile = await athleteApi.getFullProfile(this.AthleteId);
			if( isEmpty(this.athleteProfile) ) {
				throw( Error(`No athlete profile found for ${this.AthleteId}`));
			}

			this.athleteProfile.purchases = await baPurchaseApi.findByAthleteId(this.AthleteId);

			// load athlete events
			if( isEmpty(this.athleteProfile.events) ) this.athleteProfile.events = [];
			if( isNotEmpty(this.athleteProfile.purchases) ) {
				for( const purchase of this.athleteProfile.purchases ) {
					if( this.athleteProfile.events.find(e => e.id === purchase.productId) ) continue;
					const event: BAEventModel = await baEventApi.findById(purchase.productId);
					if( isNotEmpty(event) ) this.athleteProfile.events.push(event);
				}
			}

			// load events into schedule
			if( isNotEmpty(this.athleteProfile.events) ) {
				if( !this.athleteProfile.schedule ) this.athleteProfile.schedule = [];
				for( const ev of this.athleteProfile.Events ) {
					await ev.getLocation();
					const event = this.athleteProfile.schedule.find(s => s.name === ev.name );
					if( !event ) {
						this.athleteProfile.schedule.push({
							name: ev.name, 
							description: ev.description,
							date: new Date(ev.date),
							location: ev.LocationName,
							url: ev.Online? ev.meetingUrl : ev.url,
							purchased: true,
							online: ev.Online,
						})
					} else {
						event.description = ev.description;
						event.date = new Date(ev.date);
						event.location = ev.LocationName;
						event.url = ev.url;
						const today = new Date();
						if( event.date >= today ) event.archive = false;
						event.purchased = true;
					}
				}
			}

			// remove duplicates
			const seenNames = new Set<string>();
			const uniqueSchedule = this.athleteProfile.schedule.filter(item => {
				if (seenNames.has(item.name)) {
					return false;
				} else {
					seenNames.add(item.name);
					return true;
				}
			});
			this.athleteProfile.schedule = uniqueSchedule;
		}catch(e){
            this.profileError = e.message;
			console.error(`Failed to load athlete profile (${this.AthleteId}): `, e);
		}
		this.loadingAthleteProfile = false;
	}
	get IsLoadingAthleteProfile(): boolean {
		return !this.IsAthleteApiReady || this.loadingAthleteProfile || !this.athleteProfile || !this.athleteProfile.id;
	}
    get ProfileError(): string {
        if( !this.profileError ) return 'ok';
        return this.profileError;
    }
    get AthleteId(): string {
		if( this.AthleteAppActive ) {
            return userStore.athleteId;
        }
		return this.athleteId;
	}
	get AthleteName(): string {
		if( !!this.athleteProfile ) return this.athleteProfile.FullName;
		if( !!userStore ) return userStore.FullName;
		return '-';
	}
}
