// SportModel.ts 

import { FrontEndModel } from '../FrontEndModel';
import { SportName, BodyPart, BaseSoccerPositionValues } from "@/../types/enums";

export class SportModel extends FrontEndModel {
    name!: SportName;
    icon: string = '';
    active: boolean = true;
    team: boolean = true;
    positions?: string[];
    bodyParts?: BodyPart[];
    physical: boolean = false;
    physicalLabels?: string[];
    scouting: boolean = false;
    scoutingLabels?: string[];
    scoutingMoments?: string[];
    scoutingEvaluations?: string[];
    proSearch: boolean = false;

    constructor() {
        super();
        this.name = SportName.Soccer;
        this.icon = "mdi-soccer";
        this.active = true;
        this.team = true;
        this.positions = BaseSoccerPositionValues;
        this.bodyParts = [BodyPart.Foot];
        this.physical = true;
        this.physicalLabels = ['Speed','Acceleration','Agility','Power','Recovery'];
        this.scouting = true;
        this.scoutingLabels = ['Technical','Tactical','Physical','Mental'];
        this.scoutingMoments = ['Attacking Organization','Attacking Transition','Attacking Set Plays','Defending Organization','Defending Transition','Defending Set Plays'];
        this.scoutingEvaluations = ['Competitive', 'Resilience', 'Intelligence', 'Speed', 'Presence'];
        this.proSearch = false;
    }
    get Positions(): string[] | null {
        if( !this.positions ) return null;
        if( this.positions.length == 0 ) return null;

        return this.positions;
    }
}