/* CoachRoutingMixin.ts */

import { Component, Mixins } from 'vue-property-decorator';
import { Route } from 'vue-router';
import * as Routes from '../../types/constants/web_client_user.routes';
import { AppHostnameMixin } from '@/mixins';

/**
 * Provides routing routines
 */
@Component
export class CoachRoutingMixin extends Mixins(AppHostnameMixin) {
	RouteToCoachAthleteReport(reportId): Partial<Route> {
		return {
			name: Routes.CoachAthleteReport,
			params:{
				reportId,
			}
		};
	}
	RouteToCoachScoutingReport(reportId): Partial<Route> {
		return {
			name: Routes.CoachScoutingReportForm,
			params:{
				reportId,
			}
		};
	}
	RouteToCoachViewScoutingReport(reportId): Partial<Route> {
		return {
			name: Routes.ViewScoutingReport,
			params:{
				reportId,
			},
		};
	}

	RouteToCoachScoutingReportCreate(athleteId: string, teamId: string = undefined): Partial<Route> {
		return {
			name: Routes.CoachScoutingReportFormAthlete,
			params: { 
				athleteId,
				teamId,
			}
		}
	}

	RouteToTeamProgressDashboard(teamId): Partial<Route> {
		return {
			name: Routes.CoachTeamProgressDashboard,
			params: { 
				teamId,
			}
		}
	}
	RouteToCoachViewAthlete(athleteId): Partial<Route> {
		return {
			name: Routes.CoachViewAthlete,
			params: {
				athleteId,
			}
		}	
	}


	gotoCoachRoute(route: Partial<Route>) {
		if( this.AthleteAppActive ) return;
		this.$router.push(route);
	}

	gotoTeamProgressDashboard(teamId: string) {
		this.gotoCoachRoute(this.RouteToTeamProgressDashboard(teamId));
	}

	gotoCoachViewPlayerProfile(athleteId: string): void {
		this.gotoCoachRoute(this.RouteToCoachViewAthlete(athleteId))
	}


	gotoCoachAthleteReport(reportId: string = undefined) {
		this.gotoCoachRoute(this.RouteToCoachAthleteReport(reportId));
	}

	gotoCoachScoutingReport(reportId: string = null) {
		this.gotoCoachRoute(this.RouteToCoachScoutingReport(reportId));
	}
	gotoCreateScoutingReport(athleteId: string, teamId: string = undefined) {
		this.gotoCoachRoute(this.RouteToCoachScoutingReportCreate(athleteId, teamId))
	}
	gotoCoachViewScoutingReport(reportId: string) {
		this.gotoCoachRoute(this.RouteToCoachViewScoutingReport(reportId));
	}
}
