// AthleteTeamScheduleMixin.ts

import { Component, Prop, Mixins } from 'vue-property-decorator';
import { AppHostnameMixin, StringsMixin } from '@/mixins';
import { ScheduleDetails } from "@/../types";
import { TeamEventType } from '@/../types/enums';
import { AthleteProfileModel } from '@/models/athlete/AthleteProfileModel';
import { CalendarApi, CalendarEventsApi } from '@/api/CalendarApi';
import { CalendarModel } from "@/models/calendar/CalendarModel";
import { CalendarEventModel } from '@/models/calendar/CalendarEventModel';
import { teamApi } from '@/api/TeamApi';

@Component
export class AthleteTeamScheduleMixin extends Mixins(AppHostnameMixin, StringsMixin) {
    @Prop({ required: true }) athleteProfile: AthleteProfileModel;

	mounted() {
		this.loadTeamEvents();
	}

    teamsEvents: ScheduleDetails[];
    teamEventsInitialized: boolean = false;
    async loadTeamEvents() {
        this.teamEventsInitialized = false;
        this.teamsEvents = [];
        try{
            const teams = await teamApi.findByAthleteId({ athleteId: this.athleteProfile.id });
            for( const team of teams ) {
                const calendarApi = new CalendarApi('team', team.id);
                const response = await calendarApi.findAllWithAccess();
                if( this.IsNotEmptyArray(response) ) {
                    const calendar: CalendarModel = response[0];
                    const eventsApi = new CalendarEventsApi('team', team.id, calendar.id);
                    const teamEvents: CalendarEventModel[] = await eventsApi.findAllWithAccess();
                    const teamGames = teamEvents.filter(event => event.eventType === TeamEventType.Game);
                    const events: ScheduleDetails[] = teamGames.map(event => {
                        return {
                            name: event.EventName,
                            description: event.description,
                            date: new Date(event.start),
                            location: event.venue,
                            url: '',
                            archive: false,
                        }}
                    )
                    this.teamsEvents = this.teamsEvents.concat(events);
                }
            }
        } catch(e) {
            console.log(`Unable to find team for athlete`)
        }
        this.teamEventsInitialized = true;
    }
	get HasFutureTeamEvents(): boolean {
		return this.IsNotEmptyArray( this.FutureTeamEvents );
	}
	get FutureTeamEvents(): ScheduleDetails[] {
		const today = new Date();
		const future = this.teamsEvents.filter(s => s.date >= today );
		return future;
	}
}
