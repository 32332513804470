/* TeamApi.ts */
/* Implements Team API */

import { CrudApi } from './CrudApi';
import { TeamModel } from '../models/team/TeamModel';
import { PlayerOnTeam } from '../models/team/PlayerOnTeam';
import { InvitationModel } from '../models/invitation/InvitationModel';
import { LineupModel } from '../models/lineup';
import { DepthChartModel } from '../models/depthChart';
import { UniqueTokenType } from '@best-athletes/ba-types';
import { StaffMember } from '@/models/team/StaffMember';
import { TeamWTLInfo } from '@/../types/interfaces';

export class TeamApi extends CrudApi<TeamModel>{
	// resource = 'team'
	// entry is of type TeamModel
	constructor(){
		super('team', (obj) => new TeamModel().load(obj));
	}
	async shareTeam(teamId: string) {
		return await CrudApi.Api(c => c.post(`/team/${teamId}/share`));
	}

	async leaveTeam({ teamId }: { teamId: string }): Promise<TeamModel[]> {
		return await CrudApi.Api(c => c.get(`/team/${teamId}/user-leave-team`));
	}
	async validateCode({ code }: { code: string }): Promise<{valid: boolean, team: TeamModel, token: { id: string, name: string }}> {
		const response = await CrudApi.Api(c => c.get(`/team/verify/athlete-join-team-code`, { params: { joinCode: code } }));
		let team = null;
		if(response.team && response.valid === true){
			team = this.create(response.team);
		}
		return {
			valid: response.valid,
			team,
			token: response.token,
		}
	}
	async findByAthleteId({ athleteId }: { athleteId: string }): Promise<TeamModel[]> {
		return await CrudApi.Api(c => c.get(`/team/by-athlete/${athleteId}`));
	}
	async findCurrentByAthleteId(athleteId: string): Promise<TeamModel> {
		return await CrudApi.Api(c => c.get(`/team/athlete-current/${athleteId}`));
	}
	async findByCoachId({ coachId }: { coachId: string }): Promise<TeamModel[]> {
		return await CrudApi.Api(c => c.get(`/team/by-coach/${coachId}`));
	}

	async createPlayer(teamId: string, player: PlayerOnTeam): Promise<PlayerOnTeam> {
		return await CrudApi.Api(c => c.post(`/team/${teamId}/player`, player));
	}
	async loadPlayer({ teamId, playerId }: { teamId: string, playerId: string }): Promise<PlayerOnTeam> {
		return await CrudApi.Api(c => c.get(`/team/${teamId}/player/${playerId}`));
	}
	async patchPlayer({ teamId, playerId, playerPatch }: { teamId: string, playerId: string, playerPatch: Partial<PlayerOnTeam> }): Promise<PlayerOnTeam> {
		return await CrudApi.Api(c => c.patch(`/team/${teamId}/player/${playerId}`, playerPatch));
	}
	async deletePlayer({ teamId, playerId }: { teamId: string, playerId: string }): Promise<void> {
		return await CrudApi.Api(c => c.delete(`/team/${teamId}/player/${playerId}`));
	}

	async createStaff(teamId: string, staff: StaffMember): Promise<StaffMember> {
		return await CrudApi.Api(c => c.post(`/team/${teamId}/staff`, staff));
	}
	async loadStaff({ teamId, staffId }: { teamId: string, staffId: string }): Promise<StaffMember> {
		return await CrudApi.Api(c => c.get(`/team/${teamId}/staff/${staffId}`));
	}
	async patchStaff({ teamId, staffId, staffPatch }: { teamId: string, staffId: string, staffPatch: Partial<StaffMember> }): Promise<StaffMember> {
		return await CrudApi.Api(c => c.patch(`/team/${teamId}/staff/${staffId}`, staffPatch));
	}
	async deleteStaff({ teamId, staffId }: { teamId: string, staffId: string }): Promise<void> {
		return await CrudApi.Api(c => c.delete(`/team/${teamId}/staff/${staffId}`));
	}

	async createDepthChart({ teamId, depthChart }: {teamId: string, depthChart: DepthChartModel}): Promise<DepthChartModel> {
		const dc = await CrudApi.Api(c => c.post(`/team/${teamId}/depthChart`, depthChart));
		return new DepthChartModel().load(dc);
	}
	async loadDepthChart({ teamId, depthChartId }: {teamId: string, depthChartId: string}): Promise<DepthChartModel> {
		const dc = await CrudApi.Api(c => c.get(`/team/${teamId}/depthChart/${depthChartId}`));
		return new DepthChartModel().load(dc);
	}
	async patchDepthChart({ teamId, depthChartId, depthChartPatch }: {teamId: string, depthChartId: string, depthChartPatch: Partial<DepthChartModel>}): Promise<DepthChartModel> {
		const dc = await CrudApi.Api(c => c.patch(`/team/${teamId}/depthChart/${depthChartId}`, depthChartPatch));
		return new DepthChartModel().load(dc);
	}
	async deleteDepthChart({ teamId, depthChartId }: {teamId: string, depthChartId: string }): Promise<void> {
		return CrudApi.Api(c => c.delete(`/team/${teamId}/depthChart/${depthChartId}`));
	}

	async addLineup({ teamId, lineup }: { teamId: string, lineup: LineupModel }): Promise<LineupModel>{
		return await CrudApi.Api(c => c.post(`/team/${teamId}/lineup`, lineup));
	}
	async deleteLineup({ teamId, lineupId}: {teamId: string, lineupId: string}): Promise<void>{
		return await CrudApi.Api(c => c.delete(`/team/${teamId}/lineup/${lineupId}`));
	}
	async patchLineup({ teamId, lineupId, lineupPatch }: {teamId: string, lineupId: string, lineupPatch: Partial<LineupModel>}): Promise<LineupModel> {
		return await CrudApi.Api(c => c.patch(`/team/${teamId}/lineup/${lineupId}`, lineupPatch));
	}

	/**
	 * Invite Athlete to the team by Id.
	 * 
	 * @param teamId The Id of the team the athlete will join on accept
	 * @param athleteId Can be left out if the target athlete is not on the platform.
	 * @param playerId The id of the PlayerOnTeam the athlete will be linked with when accepted.
	 */
	async inviteAthleteToTeam({ teamId, athleteId, playerId }: { teamId: string, athleteId: string, playerId: string }): Promise<{invitation:InvitationModel}>{
		const response = await CrudApi.Api(c => c.post(`/team/${teamId}/invitation`, { athleteId, playerId }))
		return {
			invitation: new InvitationModel().load(response.invitation),
		};
	}
	/**
	 * Invite Athlete to the team by email.
	 * 
	 * @param teamId The Id of the team the athlete will join on accept
	 * @param email Can be left out if the target athlete is not on the platform.
	 * @param playerId The id of the PlayerOnTeam the athlete will be linked with when accepted.
	 */
	async inviteAthleteToTeamByEmail({ teamId, email, playerId }: { teamId: string, email: string, playerId: string }): Promise<{invitation:InvitationModel}>{
		const response = await CrudApi.Api(c => c.post(`/team/${teamId}/invitation/by-email`, { email, playerId }))
		return {
			invitation: new InvitationModel().load(response.invitation),
		};
	}

	/**
	 * Invite Staff to the team by email. Right now, it is used only for resending coach invitations
	 * 
	 * @param teamId The Id of the team the athlete will join on accept
	 * @param email Can be left out if the target athlete is not on the platform.
	 * @param coachId The id of the StaffMember the staff will be linked with when accepted.
	 */
	async inviteStaffToTeamByEmail({ teamId, email, staffId }: { teamId: string, email: string, staffId: string }): Promise<{invitation:InvitationModel}>{
		const response = await CrudApi.Api(c => c.post(`/team/${teamId}/invitation/staff/by-email`, { email, staffId }))
		return {
			invitation: new InvitationModel().load(response.invitation),
		};
	}

	async inviteAccept(invitationId: string): Promise<void> {
		await CrudApi.Api(c => c.post('/team/invitation/accept', { invitationId }));
	}

	async inviteDecline(invitationId: string): Promise<void>{
		await CrudApi.Api(c => c.post('/team/invitation/decline', { invitationId }));
	}

	async loadInvitationById(invitationId: string): Promise<InvitationModel> {
		const invitation = await CrudApi.Api(c => c.get(`/team/invitation/${invitationId}`));
		return new InvitationModel().load(invitation);
	}

	async joinTeamWithCode(payload: { token: { id: string, name: UniqueTokenType }, athleteId: string, teamId: string }): Promise<TeamModel> {
		const team = await CrudApi.Api(c => c.post(`/team/${payload.teamId}/join`, payload));
		return this.create(team);
	}

	async getWTLInfo(teamId: string): Promise<TeamWTLInfo> {
		const wtl = await CrudApi.Api(c => c.get(`/team/${teamId}/wtl-info`));
		return wtl;
	}
}

export const teamApi = new TeamApi();