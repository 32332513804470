// PaginatedTableMixin
//
// Mixin for pages that display a table that may contain many entries
// the data entries will be paginated in the backend
// Users require the following
// - import DataTableHeader
//		import { DataTableHeader } from 'vuetify';
// - create 'TableHeaders' getter function
// 		get TableHeaders(): Array<DataTableHeader<any>> {
//			let headers: Array<DataTableHeader<any>> = [
//				{ text: 'Name', value: 'name', sortable: false },
//			];
//			if( this.IsLargeScreen ) {
//				headers.push({text: '', value: 'actions', sortable: false });
//				headers.push({text: '', value: 'data-table-expand', sortable: false});
//			}	
//		}
// - add 'search' property
//		search: string = '';
// - add 'search' box
// 		<v-card>
// 			<v-card-text class="d-flex align-center">
// 				<v-text-field outlined dense clearable hide-details
// 					label="Search Users"
// 					prepend-inner-icon="mdi-magnify"
//			 		v-model="search"
//			 		@input="updateSearch"
//			 	/>
//			 	<v-spacer />
//			 	<div class="d-flex align-center">
//			 		<div>Found <b>{{ TotalItems }}</b> items</div>
//			 	</div>
//			 </v-card-text>
//		</v-card>
// - update 'tableOptions' if different settings are required. do this on `mounted()`
//		this.tableOptions.sortBy = ['lastName'];
//		this.tableOptions.sortDesc = [true];
// - update 'localForagePersistFields' if different fields need to be recalled. do this on `mounted()` 
// 		localForagePersistFields: Array<string | [string, any]> = [['search', ''],['tableOptions.page', 1],['tableOptions.itemsPerPage', 25]];
// - override `loadTable` function
//		async loadTable() {
//			const query: = this.TableQuery;
//			const options: QueryOptions = this.TableQueryOptions;
//		}
// - update `PageLoading` function
// 		get PageLoading(): boolean {
// 			return !this.TableLoading || adminStore.IsAthletesLoading;
// 		}
// - update `TableLoading` function
// 		get TableLoading(): boolean {
// 			return adminStore.IsAthletesLoading;
// 		}
// - create `TotalItems` function to return total number of items in database
// 		get TotalItems(): number {
// 			return adminStore.athletesCount;
// 		}
// - create a table:
//      <v-data-table dense hide-default-footer class="cursor-pointer"
//			:show-expand="IsLargeScreen"
//          :headers="TableHeaders"
//          :items="ARRAY OF ITEMS TO DISPLAY"
//          :loading="TableLoading"
//          :server-items-length="TotalItems"
//          :options.sync="tableOptions"
//      >
//		</v-data-table>
// - display expanded item (OPTIONAL)
//		<template v-slot:expanded-item="{ headers, item }">
//			<td :colspan="headers.length">
//				{{ item }}
//			</td>
//		</template>
// - create a pagination
// 		<template v-slot:footer="{}">
// 			<div class="d-flex flex-row py-2 px-4">
// 				<div v-if="IsLargeScreen" class="d-flex flex-row">
// 				<div class="d-flex align-center mr-2">Rows per page</div>
// 				<v-select outlined dense hide-details style="max-width: 100px"
// 					:items="RowsPerPageItems"
// 					item-text="name"
// 					item-value="value"
// 					v-model="tableOptions.itemsPerPage"
// 					@change="updateSearch"
// 				/>
// 				</div>
// 				<v-spacer />
// 				<v-pagination v-if="!TableLoading"
// 					v-model="tableOptions.page"
// 					@input="updateTable"
// 					:length="TotalPages"
// 					:total-visible="IsLargeScreen? '8' : '5'"
// 				/>
// 			</div>
// 		</template>

import { Component, Mixins } from 'vue-property-decorator';
import { DebounceMixin, LocalForageMixin } from '@/mixins';
import { DataOptions } from 'vuetify';
import { RepositoryQuery, QueryOptions } from '@/../types/interfaces';

@Component
export class PaginatedTableMixin extends Mixins(DebounceMixin, LocalForageMixin) {
	localForagePersistFields: Array<string | [string, any]> = [['search', ''],['tableOptions.page', 1],['tableOptions.itemsPerPage', 10]];
	tableOptions: DataOptions = {
		page: 1,
		itemsPerPage: 10,
		sortBy: ['none'],
		sortDesc: [false],
		groupBy: [],
		groupDesc: [false],
		multiSort: false,
		mustSort: false,
	};

	get HasItems(): boolean {
		return this.TotalItems > 0;
	}
	get TotalItems(): number {
		// mixin user must override this function
		return 0;
	}
	get TotalPages(): number {
		return Math.ceil(this.TotalItems / this.tableOptions.itemsPerPage);
	}

	mounted() {
		this.updateSearch();
	}

	tableUpdatePending: boolean = false;
	private debounceUpdateTable(): void{
		this.tableUpdatePending = true;
		this.debounceCallback('updateTable', async () => {
			try {
				this.tableUpdatePending = true;
				await this.updateTable();
			} catch (e) {
					console.error("Failed to update table");
			} finally {
				this.tableUpdatePending = false;
			}
		}, 500);
	}
	async updateTable(): Promise<void>{
		this.persistField(this.LocalForagePersistFieldKeys);
		return await this.loadTable();
	}
	async updateFilter(): Promise<void>{
		this.tableOptions.page = 1
		return await this.updateTable();
	}
	public async updateSearch() {
		this.tableOptions.page = 1
		this.debounceUpdateTable();
	}
	async loadTable(): Promise<void>{
        // mixin user must override this function
    }

    TableQuery<T>(search, fields): RepositoryQuery<T> {
        const query: RepositoryQuery<T> = {
            search,
            fields,
        };

        return query;
    }
    get TableQueryOptions(): QueryOptions {
        var options: QueryOptions = { 
            page: this.tableOptions.page,
            limitPerPage: this.tableOptions.itemsPerPage,
        };
        if( this.tableOptions.sortBy.length > 0 ) {
            options.sort = {
                fields: this.tableOptions.sortBy.map((field, index) => {
                    return {
                        field: field,
                        desc: this.tableOptions.sortDesc[index],
                    };
                }),
            };
        }

        return options;
    }

    get RowsPerPageItems() {
        return [
            { name: '10', value: 10 },
            { name: '25', value: 25 },
            { name: '100', value: 100 },
        ]
    }
}
