/* AthleteRoutingMixin.ts */
/* Vue mixin containing routing functions */

import { Component, Mixins } from 'vue-property-decorator';
import { AppHostnameMixin, RoutingMixin } from "@/mixins";
import { Route } from 'vue-router';
import * as Routes from '@/../types/constants/web_client_user.routes';

/**
 * Provides routing routines
 */
@Component
export class AthleteRoutingMixin extends Mixins(RoutingMixin, AppHostnameMixin) {
	get RouteToLandingPage(): Partial<Route> {
		return this.getRoute( Routes.LandingPage );
	}
	get RouteToAthleteSettings(): Partial<Route> {
		return this.getRoute( Routes.AthleteSettings );
	}
	get RouteToAthleteDashboard(): Partial<Route> {
		return this.getRoute( Routes.AthleteDashboard );
	}
	get RouteToAthleteProfile(): Partial<Route>{
		return this.getRoute( Routes.AthleteRecruitingProfileView );
	}
	get RouteToAthleteSchoolSearch(): Partial<Route>{
		return this.getRoute( Routes.AthleteSchoolSearch );
	}
	get RouteToProOrganizationSearch(): Partial<Route>{
		return this.getRoute( Routes.ProOrganizationSearch );
	}
	get RouteToAthleteSelfReport(): Partial<Route>{
		return this.getRoute( Routes.AthleteSelfReportSummary );
	}
	get RouteToAthletePhysicalAssessments(): Partial<Route>{
		return this.getRoute( Routes.AthleteVerifiedAssessmentSummary );
	}
	get RouteToAthleteMindset(): Partial<Route>{
		return this.getRoute( Routes.AthleteMindsetReport );
	}
	get RouteToAthleteScoutingReports(): Partial<Route>{
		return this.getRoute( Routes.AthleteScoutingReportsView );
	}
	get RouteToAthleteSchedule(): Partial<Route> {
		return this.getRoute( Routes.AthleteScheduleView );
	}
	get RouteToStartSelfAssessment(): Partial<Route> {
		return this.getRoute( Routes.AthleteSelfAssessmentIntro );
	}
	get RouteToAthleteVideo(): Partial<Route> {
		return this.getRoute( Routes.AthleteVideoStudio );
	}
	get RouteToAthleteSharingSummary(): Partial<Route> {
		return this.getRoute( Routes.AthleteSharingSummary );
	}
	get RouteToAthleteTeamsSummary(): Partial<Route> {
		return this.getRoute( Routes.AthleteTeamsSummary );
	}

	get RouteToAthleteComparative(): Partial<Route>{
		// If it is already on the path then just return the current path
		if (this.$route.path.startsWith('/comparative/')) {
			return {
				name: this.$route.name,
				params: this.$route.params
			}
		}

		return { name: Routes.AthleteComparativeData }
	}

	RouteToAthleteScoutingReport(reportId): Partial<Route> {
		return {
			name: Routes.AthleteScoutingReport,
			params:{
				reportId,
			}
		};
	}

	RouteToTeam(teamId: string, athleteId: string): Partial<Route> {
		if( this.CoachAppActive) return this.RouteToCoachTeam(teamId)
		return this.RouteToAthleteTeam(teamId, athleteId)
	}
	RouteToAthleteTeam(teamId: string, athleteId: string): Partial<Route>{
		return {
			name: Routes.AthleteTeamDashboard,
			params:{
				teamId,
				athleteId,
			}
		};
	}
	RouteToCoachTeam(teamId: string): Partial<Route>{
		return {
			name: Routes.TeamDashboard,
			params:{
				teamId,
			}
		};
	}

	RouteToJoinTeam(athleteId: string): Partial<Route>{
		return {
			name: Routes.AthleteTeamJoin,
			params:{
				athleteId,
			}
		}
	}

	RouteToEvent(eventId: string): Partial<Route> {
		return {
			name: Routes.AthleteBAEventDetail,
			params: {
				eventId,
			}
		}
	}

	gotoHome() {
		this.gotoRoute(this.RouteToLandingPage);
	}
	gotoAthleteDashboard() {
		this.gotoRoute(this.RouteToAthleteDashboard);
	}
	gotoAthleteProfile() {
		this.gotoRoute(this.RouteToAthleteProfile);
	}
	gotoAthleteSettings() {
		this.gotoRoute(this.RouteToAthleteSettings);
	}
	gotoEditProfile() {
		this.gotoRoute(this.RouteToAthleteSettings);
	}
	gotoAthleteVideoStudio() {
		this.gotoRoute(this.RouteToAthleteVideo);
	}
	gotoAthleteTeamsSummary() {
		this.gotoRoute(this.RouteToAthleteTeamsSummary);
	}
	gotoMindsetReport() {
		this.gotoRoute(this.RouteToAthleteMindset);
	}
	gotoPhysicalAssessments() {
		this.gotoRoute(this.RouteToAthletePhysicalAssessments);
	}
	gotoAthleteScoutingReports() {
		this.gotoRoute(this.RouteToAthleteScoutingReports);
	}
	gotoAthleteSchedulePage() {
		this.gotoRoute(this.RouteToAthleteSchedule);
	}
	gotoSelfAssessmentReports() {
		this.gotoRoute(this.RouteToAthleteSelfReport);
	}
	gotoStartSelfAssessment() {
		this.gotoRoute(this.RouteToStartSelfAssessment);
	}
    viewSharingDetails(){
		this.gotoRoute(this.RouteToAthleteSharingSummary);
	}
	
	goToScoutingReport(reportId) {
		this.gotoRoute(this.RouteToAthleteScoutingReport(reportId));
	}
	goToEvent(eventId: string) {
		this.gotoRoute(this.RouteToEvent(eventId));
	}

	gotoBA() {
		this.$router.push('/');
	}	
	gotoLogout() {
		this.logoutAthleteApp();
	}
	gotoSignUp() {
		this.$router.push('/signup');
	}
	gotoUpgrade() {
		this.$router.push('/upgrade');
	}

	get DashboardButtonWidth(): string {
		return '300'
	}
}
