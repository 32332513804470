
/* eslint-disable @typescript-eslint/no-empty-function */
import { Component, Prop, Mixins } from 'vue-property-decorator';
import { RoleName } from '@/../types/enums';
import { AuthMixin, VuetifyMixin, AppHostnameMixin, BAIconsMixin, AthleteRoutingMixin } from '@/mixins';
import { userStore, notificationStore } from '@/store';
import AppSwitcher from '@/components/navigation/AppSwitcher.vue';
import CircledAvatar from '@/components/ui/CircledAvatar.vue';
import ContextMenu from '@/components/ui/ContextMenu.vue';
import NotificationMenu from '@/components/ui/NotificationMenu.vue';
import { NotificationModel } from '@/models/notification/NotificationModel';
import MyProfilesProvider from '@/components/hoc/MyProfilesProvider.vue';

@Component({
	components: { AppSwitcher, CircledAvatar, ContextMenu, MyProfilesProvider, NotificationMenu },
})
export default class UserMenu extends Mixins(AuthMixin, AppHostnameMixin, VuetifyMixin, BAIconsMixin, AthleteRoutingMixin){
	@Prop({ type: Boolean, default: false }) dark: boolean;
	@Prop({ type: Boolean, default: false }) mobile: boolean;
	@Prop({ type: Boolean, default: false }) loading: boolean;
	@Prop({ type: Boolean, default: false }) athlete: boolean;
	@Prop({ type: Boolean, default: false }) coach: boolean;

	menu: boolean = false;

	avatarSize: number = 35;

	height: number = 1000;
	onResize(): void{
		this.height = window.innerHeight;
	}
	get ProfilesScrollWrapperStyle(): Record<string,any>{
		return {
			'max-height': `${this.height - 500}px`
		};
	}

	get Loading(): boolean{
		return this.loading;
	}

	get IconColor(): string{
		if(this.dark) return 'white';
		return 'baColorVibrantBlue'
	}

	get Notifications(): NotificationModel[]{
		return notificationStore.OrderedNotifications;
	}
	
	get Context(): RoleName{
		if(this.athlete === true) return RoleName.Athlete;
		return RoleName.Coach;
	}

	get PictureUrl(): string | undefined{
		if(!userStore.PictureUrl) return undefined;
		return userStore.PictureUrl;
	}

	get FullName(): string{
		return userStore.FullName;
	}

	addProfile(): void{
		userStore.startOnboarding();
	}

	onViewDashboard() {
		this.menu = false;
		this.gotoDashboard();
	}
}
