import Vue from 'vue';
import { Module, VuexModule, Mutation, Action, MutationAction } from 'vuex-module-decorators';
import { Auth0Client, GetTokenSilentlyOptions, RedirectLoginOptions, PopupLoginOptions } from '@auth0/auth0-spa-js'
import store from '@/store/store';
import { Auth0Plugin } from '@/auth/Auth0';
import { getInstance } from '@/auth/auth0Plug';
import { AthleteProfileModel } from '@/models/athlete/AthleteProfileModel';
import { CoachProfileModel } from '@/models/coach/CoachProfileModel';
import { userApi } from '@/api/UserApi';
import { athleteApi } from '@/api/AthleteApi';
import { coachApi } from '@/api/CoachApi';
import { RoleName, CustomerCountry } from '@/../types/enums'
import router from '@/router/router';
import * as Routes from '@/../types/constants/web_client_user.routes';
import { PasswordChangeForm, AppMetadata, UserMetadata, UserTypeMap } from '@/../types/interfaces';
import { User } from '@auth0/auth0-spa-js';
import { BAUser } from '@/../types/interfaces';
import { setGAUserId } from '@/plugins/firebase';
import { localforage } from '@/plugins/localforage';
import { Route } from 'vue-router';
import { useMixpanelTracking } from '@/plugins/mixpanel';
import { UserPreferenceModel } from '@/models/user/UserPreferenceModel';
import { UserPreferenceApi } from '@/api/UserPreferenceApi';
import { PageState } from '@/models/PageState';
import { AxiosError } from 'axios';
import { isNotEmpty } from '@/pipes';
import { config } from '@/config/config';
import { AthleteProfileRoleName, AthleteProfileRelationshipName } from '@/../types/enums';
import { AclPermission } from '@/../types/permissions';

type CurrentProfileData = {
	type: 'athlete' | 'coach',
	profileId: string,
	userId: string,
	email?: string,
};

const Mutations = {
	ADD_COACH_PROFILE: 'ADD_COACH_PROFILE',
	ADD_COACH_PROFILE_SUCCESS: 'ADD_COACH_PROFILE_SUCCESS',
	ADD_COACH_PROFILE_FAILURE: 'ADD_COACH_PROFILE_FAILURE',

	ADD_ATHLETE_PROFILE: 'ADD_ATHLETE_PROFILE',
	ADD_ATHLETE_PROFILE_SUCCESS: 'ADD_ATHLETE_PROFILE_SUCCESS',
	ADD_ATHLETE_PROFILE_FAILURE: 'ADD_ATHLETE_PROFILE_FAILURE',

	DELETE_ATHLETE_PROFILE: 'DELETE_ATHLETE_PROFILE',
	DELETE_ATHLETE_PROFILE_SUCCESS: 'DELETE_ATHLETE_PROFILE_SUCCESS',
	DELETE_ATHLETE_PROFILE_FAILURE: 'DELETE_ATHLETE_PROFILE_FAILURE',

	REMOVE_ATHLETE_PROFILE_ACCESS: 'REMOVE_ATHLETE_PROFILE_ACCESS',
	REMOVE_ATHLETE_PROFILE_ACCESS_SUCCESS: 'REMOVE_ATHLETE_PROFILE_ACCESS_SUCCESS',
	REMOVE_ATHLETE_PROFILE_ACCESS_FAILURE: 'REMOVE_ATHLETE_PROFILE_ACCESS_FAILURE',

	ADD_CHILD_ATHLETE_PROFILE: 'ADD_CHILD_ATHLETE_PROFILE',
	ADD_CHILD_ATHLETE_PROFILE_SUCCESS: 'ADD_CHILD_ATHLETE_PROFILE_SUCCESS',
	ADD_CHILD_ATHLETE_PROFILE_FAILURE: 'ADD_CHILD_ATHLETE_PROFILE_FAILURE',

	CREATE_ATHLETE_PROFILE: 'CREATE_ATHLETE_PROFILE',
	CREATE_ATHLETE_PROFILE_SUCCESS: 'CREATE_ATHLETE_PROFILE_SUCCESS',
	CREATE_ATHLETE_PROFILE_FAILURE: 'CREATE_ATHLETE_PROFILE_FAILURE',

	LOAD_ATHLETE_PROFILES: 'LOAD_ATHLETE_PROFILES',
	LOAD_ATHLETE_PROFILES_SUCCESS: 'LOAD_ATHLETE_PROFILES_SUCCESS',
	LOAD_ATHLETE_PROFILES_FAILURE: 'LOAD_ATHLETE_PROFILES_FAILURE',

	LOAD_PENDING_ATHLETE_PROFILES: 'LOAD_PENDING_ATHLETE_PROFILES',
	LOAD_PENDING_ATHLETE_PROFILES_SUCCESS: 'LOAD_PENDING_ATHLETE_PROFILES_SUCCESS',
	LOAD_PENDING_ATHLETE_PROFILES_FAILURE: 'LOAD_PENDING_ATHLETE_PROFILES_FAILURE',

	LOAD_COACH_PROFILES: 'LOAD_COACH_PROFILES',
	LOAD_COACH_PROFILES_SUCCESS: 'LOAD_COACH_PROFILES_SUCCESS',
	LOAD_COACH_PROFILES_FAILURE: 'LOAD_COACH_PROFILES_FAILURE',

	LOAD_USER_PROFILES: 'LOAD_USER_PROFILES',
	LOAD_USER_PROFILES_SUCCESS: 'LOAD_USER_PROFILES_SUCCESS',
	LOAD_USER_PROFILES_FAILURE: 'LOAD_USER_PROFILES_FAILURE',

	UPDATE_PASSWORD: 'UPDATE_PASSWORD',
	UPDATE_PASSWORD_SUCCESS: 'UPDATE_PASSWORD_SUCCESS',
	UPDATE_PASSWORD_FAILURE: 'UPDATE_PASSWORD_FAILURE',
	
	USE_ATHLETE_PROFILE: 'USE_ATHLETE_PROFILE',
	USE_COACH_PROFILE: 'USE_COACH_PROFILE',

	SET_APP_METADATA: 'SET_APP_METADATA',
	SET_USER_METADATA: 'SET_USER_METADATA',

	SET_AUTH0_LOADING: 'SET_AUTH0_LOADING',
	SET_IS_AUTHENTICATED: 'SET_IS_AUTHENTICATED',
	SET_AUTH0_USER: 'SET_AUTH0_USER',
	SET_USER: 'SET_USER',
	SET_AUTH0_CLIENT: 'SET_AUTH0_CLIENT',
	SET_TOKEN: 'SET_TOKEN',
	SET_ATHLETE_PROFILE_ID: 'SET_ATHLETE_PROFILE_ID',
	SET_COACH_PROFILE: 'SET_COACH_PROFILE',
	PUSH_ATHLETE_PROFILE: 'PUSH_ATHLETE_PROFILE',
	REMOVE_ATHLETE_PROFILE: 'REMOVE_ATHLETE_PROFILE',
}

const name = 'UserStore';

if (store.state[name]) {
	store.unregisterModule(name)
}
@Module({
	namespaced: true,
	dynamic: true,
	name,
	store: store
})
export default class UserModule extends VuexModule implements Auth0Plugin {
	$watch: any;

	get CurrentUserCountry(): CustomerCountry | null{
		if(this.currentProfileData && this.currentProfileData.type === 'athlete'){
			switch (this.AthleteProfile.country){
			case 'CA':
			case 'CAN':
			case 'Canada':
				return CustomerCountry.CA;
			case 'US':
			case 'USA':
			case 'United States':
			default:
				return CustomerCountry.US;
			}
		}
		return CustomerCountry.US;
	}

	@Action({rawError: true}) navigateTo(to: Partial<Route>): void{
		router.push(to);
	}
	@Action({rawError: true}) navigateToRoot(): void{
		router.push('/');
	}

	get IsAthlete(): boolean{
		return this.HasOwnAthleteProfile
	}
	get IsParent(): boolean{
		return (this.athleteProfiles.length === 1 && !this.HasOwnAthleteProfile) || this.athleteProfiles.length > 1;
	}
	get IsCoach(): boolean{
		return this.coachProfiles.length > 0;
	}

	get UserType(): UserTypeMap{
		return {
			IsAthlete: this.IsAthlete,
			IsParent: this.IsParent,
			IsCoach: this.IsCoach,
		}
	}

	/**
	 * Adds athlete profile to `athleteProfiles`. Replaces profile if it already exists in the list.
	 */
	@Mutation [Mutations.PUSH_ATHLETE_PROFILE](athlete: AthleteProfileModel): void{
		const index = this.athleteProfiles.findIndex(a => a.id === athlete.id);
		if(index > -1){
			this.athleteProfiles.splice(index, 1, athlete);
		}else{
			this.athleteProfiles.push(athlete);
		}
	}
	/**
	 * Adds athlete profile to `athleteProfiles`. Replaces profile if it already exists in the list.
	 */
	@Mutation [Mutations.REMOVE_ATHLETE_PROFILE](athleteId: string): void{
		const index = this.athleteProfiles.findIndex(a => a.id === athleteId);
		if(index > -1){
			this.athleteProfiles.splice(index, 1);
		}
	}

	/**
	 * Sends the user to the Onboarding process for an athlete. If the user doesn't have their own profile, they will have the option to make one
	 */
	@Action startAthleteOnboarding({ role }: { role: RoleName }): void{
		if(!this.HasOwnAthleteProfile){
			const params: any = {
				name: Routes.RootOnboarding,
			};
			router.push(params);
		}else{
			const params: any = {
				name: Routes.AthleteProfileSetup,
				params: {
					injectSteps: {
						step1:{
							firstName: "",
							lastName: "",
							role,
						}
					},
					startingStep: 1,
				},
			};
			router.push(params);
		}
	}
	/**
	 * Sends the user to the Onboarding process for a new profile
	 */
	@Action startOnboarding(): void{
		const params: any = {
			name: Routes.RootOnboarding,
		};
		router.push(params);
	}

	loading: Auth0Plugin['loading'] = true;
	isAuthenticated: Auth0Plugin['isAuthenticated'] = false;
	user: Auth0Plugin['user'] = null;
	auth0User: Auth0Plugin['auth0User'] = null;
	auth0Client: Auth0Plugin['auth0Client'] = null;
	popupOpen: Auth0Plugin['popupOpen'] = false;
	error: Auth0Plugin['error'] = null;
	token: Auth0Plugin['token'] = null;

	get UserRoles(): RoleName[]{
		// DEBUG PROD DB (search DEBUG_PROD)
		// return [RoleName.Admin, RoleName.ClubAdmin, RoleName.UserAdmin, RoleName.FreeUser, RoleName.Coach];

		if(!this.user) return [];
		if(!Array.isArray(this.user.roles)) return [];
		return this.user.roles;
	}

	get IsReady(): boolean {
		return this.token !== null;
	}
	get PictureUrl(): string {
		if (!this.user){
			return '';
		}
		return this.user.pictureUrl;
	}
	get FullName(): string {
		if (this.user && this.user.firstName){
			return `${this.user.firstName} ${this.user.lastName}`;
		}else if (this.currentProfileData && this.currentProfileData.type === 'athlete'){
			return this.athlete.FullName;
		}else if (this.currentProfileData && this.currentProfileData.type === 'coach'){
			return this.CoachProfile.FullName;
		}
		return '';
	}
	get authId(): string {
		if (!this.auth0User) return undefined;
		return this.auth0User.sub;
	}
	get athleteId(): string {
		if (this.IsImpersonatingAthlete){
			return this.currentProfileData.profileId;
		}
		if (this.user){
			return this.user.athleteId;
		}
		return null;
	}
	get athlete(): AthleteProfileModel | undefined {
		return this.athleteProfiles.find(p => p.id === this.athleteId);
	}
	get coachId(): string {
		if (this.user) return this.user.coachId;
		return null;
	}
	get completedProfile(): boolean {
		return false;
	}
	get HasCoachProfile(): boolean {
		return this.user !== null && this.user.coachId !== null && this.user.coachId !== undefined;
	}
	get HasAthleteProfile(): boolean {
		if (this.IsImpersonatingAthlete){
			return true;
		}
		if (this.athleteProfiles.length > 0){
			return true;
		}
		return this.user !== null && this.user.athleteId !== null && this.user.athleteId !== undefined;
	}
	get IsInstructorAdmin(): boolean {
		return this.UserRoles.includes(RoleName.InstructorAdmin);
	}
	get ParentUpgradeRequired(): boolean {
		if(!this.HasAthleteProfiles){
			return false;
		}
		if(this.user.app_metadata === undefined){
			return true;
		}
		if (this.user.app_metadata.parentUpgradeComplete !== true){
			return true;
		}
		return false;
	}
	get EmailVerified(): boolean{
		if (this.user) return this.user.email_verified
		return false
	}

	@Action({
		rawError: true,
	})
	setAuth0Client(client: Auth0Client): void{
		this.context.commit(Mutations.SET_AUTH0_CLIENT, client);
	}
	@Mutation [Mutations.SET_AUTH0_CLIENT](client: Auth0Client): void{
		this.auth0Client = client;
	}

	@Action({
		rawError: true,
	})
	setLoading(loading: boolean): void{
		this.context.commit(Mutations.SET_AUTH0_LOADING, loading);
	}
	@Mutation [Mutations.SET_AUTH0_LOADING](loading: boolean): void{
		this.loading = loading;
	}

	@Action({
		rawError: true,
	})
	setIsAuthenticated(isAuthenticated: boolean): void{
		this.context.commit(Mutations.SET_IS_AUTHENTICATED, isAuthenticated);
	}
	@Mutation [Mutations.SET_IS_AUTHENTICATED](isAuthenticated: boolean): void{
		this.isAuthenticated = isAuthenticated;
	}

	@Action({
		rawError: true,
	})
	setAuth0User(auth0User: Auth0Plugin['auth0User']): void{
		this.context.commit(Mutations.SET_AUTH0_USER, auth0User);
	}
	@Mutation [Mutations.SET_AUTH0_USER](auth0User: Auth0Plugin['auth0User']): void{
		this.auth0User = auth0User;
	}

	/**
	 * Sets user identity properties for;
	 *  - Google Analytics
	 */
	@Action({
		rawError: true,
	})
	async identifyUser(): Promise<void> {
		setGAUserId(this.user.id);
		useMixpanelTracking(this.user, this.UserType);
	}

	@Action({
		rawError: true,
	})
	async setUser(user: BAUser): Promise<void>{
		this.context.commit(Mutations.SET_USER, user);
	}
	@Mutation [Mutations.SET_USER](user: Auth0Plugin['user']): void{
		this.user = user;
	}
	@Action({ rawError: true })
	async refreshUser(): Promise<BAUser> {
		try{
			const baUser: BAUser = await userApi.findByAuthId(this.authId);
			if( isNotEmpty(baUser) ) {
				await this.setUser(baUser);
				return baUser;
			} else {
				throw(new Error(`Unable to find user ${this.authId} during refreshUser`));
			}
		} catch(e) {
			// Handle other errors
			console.error(e);
			throw(e);
		}
	}

	get UserMetadata(): UserMetadata{
		return this.user.user_metadata ?? {};
	}

	@Action({ rawError:true })
	async updateAfterLogin(): Promise<User> {
		try{
			const options: PopupLoginOptions = {
				prompt: 'login',
				screen_hint: 'login',
				connection: 'Username-Password-Authentication',
				audience: config.Auth0Config.audience,
				scope: 'openid profile email roles',
			};	
		
			// obtain status after login
			const isAuthenticated = await this.auth0Client.isAuthenticated();
			const user: User = await this.auth0Client.getUser(options);

			// success. update the user store
			await this.setIsAuthenticated(isAuthenticated);
			await this.setAuth0User(user);

			await this.refreshUser();

			const auth0user: User = await this.auth0Client.getUser(options);
			return auth0user;
		} catch(e) {
			// Handle other errors
			console.error(e);
			return undefined;
		}
	}
	@Action({ rawError:true })
	async loginWithPopup(o: PopupLoginOptions): Promise<boolean> {
		try{
			o.audience = config.Auth0Config.audience;
			o.scope = 'openid profile email roles'
			await this.auth0Client.loginWithPopup(o);
			const user = await this.auth0Client.getUser(o);
			this.setAuth0User(user);
			const isAuthenticated = await this.auth0Client.isAuthenticated();
			this.setIsAuthenticated(isAuthenticated);
			return true;
		} catch(e) {
			if( e instanceof Error && e.message.includes('Popup closed') ) {
				// Popup was closed without completing login
				console.log('Popup closed without completing login');
			} else {
				// Handle other errors
				console.error(e);
				throw(e);
			}
			return false;
		}
	}

	@Action({ rawError:true })
	async loginWithRedirect(o: RedirectLoginOptions) {
		await this.auth0Client.loginWithRedirect(o);
	}
	@Action({ rawError: true })
	async refreshToken(ignoreCache = false): Promise<string> {
		if( !this.isAuthenticated ) return "";

		const token = await this.auth0Client.getTokenSilently({ ignoreCache });
		this.context.commit(Mutations.SET_TOKEN, token);
		return this.token;
	}
	
	@Mutation [Mutations.SET_TOKEN](token: string): void{
		this.token = token;
	}

	@Action({ rawError: true })
	getTokenSilently(o: GetTokenSilentlyOptions): Promise<string> {
		return this.auth0Client.getTokenSilently(o);
	}
	@Action({ rawError: true })
	logout(): void {
		this.auth0Client.logout({
			returnTo: window.location.origin
		});
	}
	@Action({ rawError: true })
	setAthleteProfile(athlete: AthleteProfileModel): void {
		this.context.commit(Mutations.SET_ATHLETE_PROFILE_ID, athlete.id);
		this.context.commit(Mutations.PUSH_ATHLETE_PROFILE, athlete);
	}
	/**
	 * Inserts or overwrites the Athlete Profile in the user store.
	 */
	@Action({ rawError: true })
	pushAthleteProfile(athlete: AthleteProfileModel): void {
		this.context.commit(Mutations.PUSH_ATHLETE_PROFILE, athlete);
	}
	@Mutation [Mutations.SET_ATHLETE_PROFILE_ID](athleteId: string): void {
		this.user.athleteId = athleteId;
	}
	@Action({ rawError: true })
	setCoachProfile(coach: CoachProfileModel): void {
		this.context.commit(Mutations.SET_COACH_PROFILE, coach.id);
	}
	@Mutation [Mutations.SET_COACH_PROFILE](coachId: string): void{
		this.user.coachId = coachId;
	}

	@Action({ rawError: true })
	async addUserRoles(roles: RoleName[]): Promise<void> {
		for (const role of roles) {
			if (!this.UserRoles.includes(role)) {
				this.UserRoles.push(role);
			}
		}
		await userApi.addUserRoles(this.user.roles);
	}
	@Action({ rawError: true })
	async setUserRoles(roles: RoleName[]): Promise<void> {
		this.user.roles = roles;
		await userApi.addUserRoles(this.user.roles);
	}

	updatePasswordLoading: boolean = false;
	@Action({ rawError: true })
	async updatePassword({ passwordChange }: { passwordChange: PasswordChangeForm }): Promise<void> {
		this.context.commit(Mutations.UPDATE_PASSWORD);
		try {
			console.log("TODO: Implement password change", { passwordChange });
			this.context.commit(Mutations.UPDATE_PASSWORD_SUCCESS);
		} catch (e) {
			console.error("Failed to Update Password", e);
			this.context.commit(Mutations.UPDATE_PASSWORD_FAILURE, e);
		}
	}

	@Mutation [Mutations.UPDATE_PASSWORD](): void {
		this.updatePasswordLoading = true;
	}
	@Mutation [Mutations.UPDATE_PASSWORD_SUCCESS](): void {
		this.updatePasswordLoading = false;
	}
	@Mutation [Mutations.UPDATE_PASSWORD_FAILURE](error: any): void {
		this.updatePasswordLoading = false;
	}


	addCoachProfileLoading: boolean = false;

	@Action({ rawError: true })
	async addCoachProfile({ coach }: { coach: CoachProfileModel }): Promise<CoachProfileModel> {
		this.context.commit(Mutations.ADD_COACH_PROFILE);
		try {
			const auth0: Auth0Plugin = getInstance();
			
			const newCoach = await userApi.addCoachProfile(coach, {
				withCredentials: true,
			});

			this.context.commit(Mutations.ADD_COACH_PROFILE_SUCCESS, newCoach);

			auth0.setCoachProfile(newCoach);
			return newCoach;
		} catch (e) {
			console.error("Failed to Create Coach Profile", e);
			this.context.commit(Mutations.ADD_COACH_PROFILE_FAILURE, e);
		}
	}

	@Mutation [Mutations.ADD_COACH_PROFILE](): void {
		this.addCoachProfileLoading = true;
	}
	@Mutation [Mutations.ADD_COACH_PROFILE_SUCCESS](coach: CoachProfileModel): void {
		const index = this.coachProfiles.findIndex(c => c.id === coach.id);
		if(index > -1){
			this.coachProfiles.splice(index, 1, coach);
		}else{
			this.coachProfiles.push(coach);
		}
		this.addCoachProfileLoading = false;
	}
	@Mutation [Mutations.ADD_COACH_PROFILE_FAILURE](error: any): void {
		this.addCoachProfileLoading = false;
	}

	addAthleteProfileLoading: boolean = false;

	@Action({
		rawError: true
	})
	async addChildAthleteProfile({ athlete }: { athlete: AthleteProfileModel }): Promise<AthleteProfileModel> {
		this.context.commit(Mutations.ADD_CHILD_ATHLETE_PROFILE);
		try {
			const child = await athleteApi.insertWithOwnership(athlete, {
				withCredentials: true,
			});
			this.context.commit(Mutations.PUSH_ATHLETE_PROFILE, child);
			this.context.commit(Mutations.ADD_CHILD_ATHLETE_PROFILE_SUCCESS, child);
			this.context.commit(Mutations.ADD_ATHLETE_PROFILE_SUCCESS, child);
			return child;
		} catch (e) {
			console.error("Failed to Add Child Athlete Profile", e);
			this.context.commit(Mutations.ADD_CHILD_ATHLETE_PROFILE_FAILURE, e);
		}
	}
	@Mutation [Mutations.ADD_CHILD_ATHLETE_PROFILE](): void {
		this.addAthleteProfileLoading = true;
	}
	@Mutation [Mutations.ADD_CHILD_ATHLETE_PROFILE_SUCCESS](): void {
		this.addAthleteProfileLoading = false;
	}
	@Mutation [Mutations.ADD_CHILD_ATHLETE_PROFILE_FAILURE](error: any): void {
		this.addAthleteProfileLoading = false;
	}

	@Action({
		rawError: true
	})
	async addAthleteProfile({ athlete }: { athlete: AthleteProfileModel }): Promise<AthleteProfileModel> {
		this.context.commit(Mutations.ADD_ATHLETE_PROFILE);
		try {
			const auth0: Auth0Plugin = getInstance();

			const addedAthlete = await userApi.addAthleteProfile(athlete, { withCredentials: true });
			this.context.commit(Mutations.ADD_ATHLETE_PROFILE_SUCCESS, addedAthlete);

			auth0.setAthleteProfile(addedAthlete);
			return addedAthlete;
		} catch (e) {
			console.error("Failed to Add Athlete Profile", e);
			this.context.commit(Mutations.ADD_ATHLETE_PROFILE_FAILURE, e);
		}
	}

	@Mutation [Mutations.ADD_ATHLETE_PROFILE](): void {
		this.addAthleteProfileLoading = true;
	}
	@Mutation [Mutations.ADD_ATHLETE_PROFILE_SUCCESS](athlete: AthleteProfileModel): void {
		const index = this.athleteProfiles.findIndex(a => a.id === athlete.id);
		if (index > -1) {
			this.athleteProfiles.splice(index, 1, athlete);
		} else {
			this.athleteProfiles.push(athlete);
		}
		this.addAthleteProfileLoading = false;
	}
	@Mutation [Mutations.ADD_ATHLETE_PROFILE_FAILURE](error: any): void {
		this.addAthleteProfileLoading = false;
	}

	deleteAthleteProfileLoading: boolean = false;
	@Action({
		rawError: true
	})
	async deleteAthleteProfile({ athleteId }: { athleteId: string }): Promise<void> {
		this.context.commit(Mutations.DELETE_ATHLETE_PROFILE);
		try {
			await athleteApi.delete(new AthleteProfileModel().load({ id: athleteId }));
			this.context.commit(Mutations.REMOVE_ATHLETE_PROFILE, athleteId);
			this.context.commit(Mutations.DELETE_ATHLETE_PROFILE_SUCCESS, athleteId);
		} catch (e) {
			console.error("Failed to Delete Athlete Profile", e);
			this.context.commit(Mutations.DELETE_ATHLETE_PROFILE_FAILURE, e);
		}
	}

	@Mutation [Mutations.DELETE_ATHLETE_PROFILE](): void {
		this.deleteAthleteProfileLoading = true;
	}
	@Mutation [Mutations.DELETE_ATHLETE_PROFILE_SUCCESS](): void {
		this.deleteAthleteProfileLoading = false;
	}
	@Mutation [Mutations.DELETE_ATHLETE_PROFILE_FAILURE](error: any): void {
		this.deleteAthleteProfileLoading = false;
	}

	removeAthleteProfileLoading: boolean = false;
	@Action({
		rawError: true
	})
	async removeAthleteProfileAccess({ athlete, userId }: { athlete: AthleteProfileModel, userId: string }): Promise<void> {
		this.context.commit(Mutations.REMOVE_ATHLETE_PROFILE_ACCESS);
		try {
			athlete.removeUser(userId);
			const newAthlete = await athleteApi.patchWithAcls(athlete);
			this.context.commit(Mutations.REMOVE_ATHLETE_PROFILE, newAthlete.id);
			this.context.commit(Mutations.REMOVE_ATHLETE_PROFILE_ACCESS_SUCCESS, newAthlete.id);
		} catch (e) {
			console.error("Failed to Remove Athlete Profile", e);
			this.context.commit(Mutations.REMOVE_ATHLETE_PROFILE_ACCESS_FAILURE, e);
		}
	}

	@Mutation [Mutations.REMOVE_ATHLETE_PROFILE_ACCESS](): void {
		this.removeAthleteProfileLoading = true;
	}
	@Mutation [Mutations.REMOVE_ATHLETE_PROFILE_ACCESS_SUCCESS](): void {
		this.removeAthleteProfileLoading = false;
	}
	@Mutation [Mutations.REMOVE_ATHLETE_PROFILE_ACCESS_FAILURE](error: any): void {
		this.removeAthleteProfileLoading = false;
	}

	createAthleteProfileLoading: boolean = false;

	@Action({
		rawError: true
	})
	async createAthleteProfile({athlete} : {athlete: AthleteProfileModel}): Promise<AthleteProfileModel> {
		this.context.commit(Mutations.CREATE_ATHLETE_PROFILE);
		try {
			const user: User = this.auth0User;
			const baUser: BAUser = this.user;

			athlete.setUser(user.sub, {
				userId: user.sub,
				role: {
					name: AthleteProfileRoleName.Owner,
					permissions: [AclPermission.Owner],
				},
				relationship: {
					name: AthleteProfileRelationshipName.Athlete,
				},
			});
			const createdAthlete = await athleteApi.createAthleteProfile(athlete);
			if( !createdAthlete ) {
				throw( Error(`Unable to create athlete profile ${athlete.email}`));
				return createdAthlete;
			}

			const newAthlete = await athleteApi.insertWithOwnership(createdAthlete);
			if( !newAthlete ) {
				throw( Error(`Unable to insert athlete ${createdAthlete.id}:${createdAthlete.email}`));
				return createdAthlete;
			}

			newAthlete.setUser(user.sub, {
				userId: user.sub,
				role: {
					name: AthleteProfileRoleName.Owner,
					permissions: [AclPermission.Owner],
				},
				relationship: {
					name: AthleteProfileRelationshipName.Athlete,
				},
			});
			
			const userAthlete = await this.addAthleteProfile({ athlete: newAthlete });
			this.context.commit(Mutations.CREATE_ATHLETE_PROFILE_SUCCESS, {athlete: userAthlete});
			return newAthlete;
		} catch (e) {
			console.error("Failed to Create Athlete Profile", e);
			this.context.commit(Mutations.CREATE_ATHLETE_PROFILE_FAILURE, e);
		}
	}

	@Mutation [Mutations.CREATE_ATHLETE_PROFILE](): void {
		this.createAthleteProfileLoading = true;
	}
	@Mutation [Mutations.CREATE_ATHLETE_PROFILE_SUCCESS]({ athlete }: {athlete: AthleteProfileModel}): void {
		const index = this.athleteProfiles.findIndex(a => a.id === athlete.id);
		if(index > -1){
			this.athleteProfiles.splice(index, 1, athlete);
		}else{
			this.athleteProfiles.push(athlete);
		}
		this.createAthleteProfileLoading = false;
	}
	@Mutation [Mutations.CREATE_ATHLETE_PROFILE_FAILURE](error: any): void {
		this.createAthleteProfileLoading = false;
	}

	currentProfileData: CurrentProfileData | null = null;

	loadAthleteProfilesLoading: boolean = false;
	athleteProfilesInitialized: boolean = false;
	athleteProfiles: AthleteProfileModel[] = [];
	userPreference: UserPreferenceModel | null = null;

	/** Deprecated - impersonation is now invisible to the user*/
	get IsImpersonatingAthlete(): boolean{
		return this.HasAthleteProfiles && this.currentProfileData !== null && this.currentProfileData.type === 'athlete';
	}
	get HasAthleteProfiles(): boolean{
		return this.MyAthleteProfileIds.length > 0;
	}
	/**
	 * Does the current user have their own Athlete profile?
	 */
	get HasOwnAthleteProfile(): boolean{
		return this.OwnAthleteProfile !== null;
	}
	/**
	 * Does the current user have their own Athlete profile?
	 */
	get OwnAthleteProfile(): AthleteProfileModel | null{
		const athlete = this.MyAthleteProfiles.find(a => {
			return a.isAthlete(this.user.id);
		});
		return athlete ?? null;
	}
	/**
	 * Does the current user have athlete profiles other than their own?
	 */
	get HasAdditionalAthleteProfiles(): boolean{
		const athlete = this.MyAthleteProfiles.find(a => {
			return !a.isAthlete(this.user.id);
		});
		return athlete !== undefined;
	}

	/**
	 * Returns list of Profile Ids which are not the User's Athlete Profile
	 */
	get MyAthleteProfileIds(): string[]{
		return this.MyAthleteProfiles.map(p => p.id);
	}
	get MyAthleteProfiles(): AthleteProfileModel[] {
		return this.athleteProfiles.sort((a) => {
			if(a.isAthlete(this.user.id)) return -1;
			return 0;
		});
	}

	/**
	 * Returns the current athlete profile if it exists.
	 */
	get AthleteProfile(): AthleteProfileModel {
		if( !this.athleteId ) return undefined;

		const profile = this.athleteProfiles.find(profile => profile.id === this.athleteId);
		return profile;
	}

	@Action({ rawError: true }) async useDefaultProfile(): Promise<void> {
		const currentProfileData = await this.getCurrentProfileData();
		let gotValidProfileId: boolean = false;
		if (currentProfileData && currentProfileData.type === 'coach'){
			gotValidProfileId = await this.useCoachProfile({
				profileId: currentProfileData.profileId,
			});
		} else if (currentProfileData && currentProfileData.type === 'athlete'){
			gotValidProfileId = await this.useAthleteProfile({
				profileId: currentProfileData.profileId,
			});
		}
		
		// Fallback if the provided profile id was not valid
		if (!gotValidProfileId && this.coachProfiles.length > 0) {
			const [coach] = this.coachProfiles
			gotValidProfileId = await this.useCoachProfile({
				profileId: coach.id,
			});
		}
		if (!gotValidProfileId && this.athleteProfiles.length > 0) {
			const [athlete] = this.athleteProfiles
			gotValidProfileId = await this.useAthleteProfile({
				profileId: athlete.id,
			});
		}
	
		if (currentProfileData && (currentProfileData.type === 'athlete' || currentProfileData.type === 'coach')) {			
			await this.loadUserPreference({userType: currentProfileData.type, id: currentProfileData.profileId});
		}
	}

	@Action({ rawError: true }) async useDefaultAthleteProfile(): Promise<void> {
		if (this.athleteProfiles.length === 0){
			console.warn("No athlete profiles available");
		}
		const [athlete] = this.athleteProfiles
		this.useAthleteProfile({
			profileId: athlete.id,
		});
	}

	@Action({
		rawError: true
	})
	async loadAthleteProfiles(): Promise<AthleteProfileModel[]> {
		this.context.commit(Mutations.LOAD_ATHLETE_PROFILES);
		try {
			const athletes = await athleteApi.findAllWithAccess();
			this.context.commit(Mutations.LOAD_ATHLETE_PROFILES_SUCCESS, { athletes });
			if (this.athleteId === null) {
				this.useDefaultProfile();
			}
			return athletes;
		} catch (e) {
			console.error("Failed to Load Athlete Profile", e);
			this.context.commit(Mutations.LOAD_ATHLETE_PROFILES_FAILURE, e);
		}
	}

	@Mutation [Mutations.LOAD_ATHLETE_PROFILES](): void {
		this.loadAthleteProfilesLoading = true;
	}
	@Mutation [Mutations.LOAD_ATHLETE_PROFILES_SUCCESS]({ athletes }: {athletes: AthleteProfileModel[]}): void {
		this.athleteProfiles = athletes;
		this.loadAthleteProfilesLoading = false;
		this.athleteProfilesInitialized = true;
	}
	@Mutation [Mutations.LOAD_ATHLETE_PROFILES_FAILURE](error: any): void {
		this.loadAthleteProfilesLoading = false;
	}

	pendingAthleteProfiles: AthleteProfileModel[] = [];
	pendingAthleteProfilesState: PageState = new PageState("Initial");
	@Action({
		rawError: true
	})
	async loadPendingAthleteProfiles(): Promise<AthleteProfileModel[]> {
		this.context.commit(Mutations.LOAD_PENDING_ATHLETE_PROFILES);
		try {
			const athletes = await athleteApi.getAvailableProfilesForUser();
			this.context.commit(Mutations.LOAD_PENDING_ATHLETE_PROFILES_SUCCESS, { athletes });
			return athletes;
		} catch (e) {
			this.context.commit(Mutations.LOAD_PENDING_ATHLETE_PROFILES_FAILURE, e);
		}
	}

	@Mutation [Mutations.LOAD_PENDING_ATHLETE_PROFILES](): void {
		this.pendingAthleteProfilesState = new PageState("Loading");
	}
	@Mutation [Mutations.LOAD_PENDING_ATHLETE_PROFILES_SUCCESS]({ athletes }: { athletes: AthleteProfileModel[] }): void {
		this.pendingAthleteProfiles = athletes;
		this.pendingAthleteProfilesState = new PageState("Ready");
	}
	@Mutation [Mutations.LOAD_PENDING_ATHLETE_PROFILES_FAILURE](error: AxiosError<any>): void {
		this.pendingAthleteProfilesState = PageState.getPageState(error);
	}

	loadCoachProfilesLoading: boolean = false;
	coachProfilesInitialized: boolean = false;
	coachProfiles: CoachProfileModel[] = [];
	get CoachProfile(): CoachProfileModel | null{
		const [coach] = this.coachProfiles;
		return coach ?? null;
	}

	@Action({
		rawError: true
	})
	async loadCoachProfiles(): Promise<CoachProfileModel[]> {
		this.context.commit(Mutations.LOAD_COACH_PROFILES);
		try {
			const coach = await coachApi.findById(this.user.coachId);
			/** For the time being, the user can only have a single coach profile; but the "impersonation" of the profile will behave similarly to athlete profiles */
			const coachProfiles = coach === null ? [] : [coach];
			this.context.commit(Mutations.LOAD_COACH_PROFILES_SUCCESS, {
				coach: coachProfiles,
			});
			return coachProfiles;
		} catch (e) {
			console.error("Failed to Create Coach Profile", e);
			this.context.commit(Mutations.LOAD_COACH_PROFILES_FAILURE, e);
		}
	}

	@Mutation [Mutations.LOAD_COACH_PROFILES](): void {
		this.loadCoachProfilesLoading = true;
	}
	@Mutation [Mutations.LOAD_COACH_PROFILES_SUCCESS]({ coach }: {coach: CoachProfileModel[]}): void {
		this.coachProfiles = coach;
		this.loadCoachProfilesLoading = false;
		this.coachProfilesInitialized = true;
	}
	@Mutation [Mutations.LOAD_COACH_PROFILES_FAILURE](error: any): void {
		this.loadCoachProfilesLoading = false;
	}


	loadUserProfilesLoading: boolean = false;
	userProfilesInitialized: boolean = false;
	@Action({
		rawError: true
	})
	async loadUserProfiles(): Promise<void> {
		this.context.commit(Mutations.LOAD_USER_PROFILES);
		try {
			const athletes = await athleteApi.findAllWithAccess();
			const coach = this.user.coachId? await coachApi.findById(this.user.coachId) : null;

			this.context.commit(Mutations.LOAD_COACH_PROFILES_SUCCESS, { coach: coach === null ? [] : [coach] });
			this.context.commit(Mutations.LOAD_ATHLETE_PROFILES_SUCCESS, { athletes });
			this.context.commit(Mutations.LOAD_USER_PROFILES_SUCCESS);
			if(this.currentProfileData === null){
				await this.useDefaultProfile();
			}
			this.identifyUser();
		} catch (e) {
			console.error("Failed to Load User's Athlete Profile", e);
			this.context.commit(Mutations.LOAD_USER_PROFILES_FAILURE, e);
		}
	}

	@Mutation [Mutations.LOAD_USER_PROFILES](): void {
		this.loadUserProfilesLoading = true;
	}
	@Mutation [Mutations.LOAD_USER_PROFILES_SUCCESS](): void {
		this.loadUserProfilesLoading = false;
		this.userProfilesInitialized = true;
	}
	@Mutation [Mutations.LOAD_USER_PROFILES_FAILURE](error: any): void {
		this.loadUserProfilesLoading = false;
	}

	get CurrentProfileIdKey(): string{
		return `${this.user.id}|currentProfileId`;
	}

	@Action({ rawError: true }) async getCurrentProfileData(): Promise<CurrentProfileData> {
		return localforage.getItem(this.CurrentProfileIdKey);
	}
	@Action({ rawError: true }) async setCurrentProfileData(profile: CurrentProfileData): Promise<void> {
		await localforage.setItem(this.CurrentProfileIdKey, profile);
		this.identifyUser();
	}

	/** Selects an athlete or coach profile by id without knowing which type it is */
	@Action({ rawError: true })
	async useProfile({ profileId }: { profileId: string }): Promise<'athlete' | 'coach' | null> {
		if(await this.useAthleteProfile({ profileId }) === true){
			return 'athlete';
		}else if(await this.useCoachProfile({ profileId }) === true){
			return 'coach';
		}
		return null;
	}
	
	@Action({ rawError: true })
	async useAthleteProfile({ profileId }: { profileId: string }): Promise<boolean> {
		const validId = this.athleteProfiles.find(a => a.id === profileId) !== undefined;
		if (validId){
			const profile: CurrentProfileData = {
				type: 'athlete',
				profileId: profileId,
				userId: this.user.id,
				email: this.user.email,
			};
			this.setCurrentProfileData(profile);
			this.context.commit(Mutations.USE_ATHLETE_PROFILE, profile);
			await this.loadUserPreference({userType: 'athlete', id: profileId});
		}else{
			console.warn("Athlete Id was invalid", profileId);
		}
		return validId;
	}
	@Mutation [Mutations.USE_ATHLETE_PROFILE](profile: CurrentProfileData): void {
		this.currentProfileData = profile;
	}

	@Action({ rawError: true })
	async useCoachProfile({ profileId }: { profileId: string }): Promise<boolean> {
		const validId = this.coachProfiles.find(a => a.id === profileId) !== undefined;
		if (validId){
			const profile: CurrentProfileData = {
				type: 'coach',
				profileId: profileId,
				userId: this.user.id,
				email: this.user.email,
			};
			this.setCurrentProfileData(profile);
			this.context.commit(Mutations.USE_COACH_PROFILE, profile);
			await this.loadUserPreference({userType: 'coach', id: profileId});
		}else{
			console.warn("Coach Id was not valid", profileId);
		}
		return validId;
	}
	@Mutation [Mutations.USE_COACH_PROFILE](profile: CurrentProfileData): void {
		this.currentProfileData = profile;
	}

	/**
	 * Load the preference using the nested controller
	 * @param userType 'athlete' or 'coach'
	 * @param id ID of athlete of coach
	 */
	@MutationAction
	async loadUserPreference({ userType, id }: { userType: 'coach' | 'athlete', id: string }) : Promise<{ userPreference: UserPreferenceModel }>{
		const preferences = await new UserPreferenceApi(userType, id).findOneByParentIdOrCreate();

		if (preferences) {
			return { userPreference : preferences }
		}
		else {
			console.log(`Error could not load any ${userType} preference`);
			return { userPreference: null }
		}
	}


	@Action async completeUpgradeV1(): Promise<void> {
		const app_metadata = await userApi.parentUpgradeComplete();
		this.context.commit(Mutations.SET_APP_METADATA, { app_metadata })
		await this.refreshToken();
	}

	@Action({ rawError: true }) async acceptTermsOfService(tosAccepted: boolean): Promise<void> {
		const userMetadata = await userApi.acceptTermsOfService(tosAccepted);
		this.context.commit(Mutations.SET_USER_METADATA, { user_metadata: userMetadata });
	}

	@Mutation [Mutations.SET_APP_METADATA]({ app_metadata }: { app_metadata?: AppMetadata }): void {
		Vue.set(this.user, 'app_metadata', app_metadata);
	}
	@Action async updateUserMetadata({user_metadata}: { user_metadata: Partial<UserMetadata> }): Promise<void>{
		this.context.commit(Mutations.SET_USER_METADATA, {
			user_metadata:{
				...this.UserMetadata,
				...user_metadata,
			}
		});
		const response = await userApi.updateUserMetadata({
			...this.user.user_metadata,
			...user_metadata,
		});
		this.context.commit(Mutations.SET_USER_METADATA, response)
	}
	@Mutation [Mutations.SET_USER_METADATA]({ user_metadata }: { user_metadata?: UserMetadata }): void {
		Vue.set(this.user, 'user_metadata', user_metadata);
	}
}
